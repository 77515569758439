import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Helmet from 'react-helmet'

import InterfaceContext from '../context/InterfaceContext'
import ProductPage from '../components/ProductPage'

const removeCareInstructions = desc =>
  desc.split(/Care Instructions/).slice(0, 1)

const Product = props => {
  const {
    data: { site, product },
    location: { pathname }
  } = props
  const {
    siteMetadata: { siteUrl }
  } = site

  const description = removeCareInstructions(product.description)
  const canonical = `${siteUrl}${pathname}`

  return (
    <InterfaceContext.Consumer>
      {({
        isDesktopViewport,
        productImagesBrowserStatus,
        productImageFeatured,
        toggleProductImagesBrowser,
        setCurrentProductImages
      }) => (
        <>
          <Helmet>
            <title>{product.title}</title>
            <meta name='description' content={description} />
            <link rel='canonical' href={canonical} />
          </Helmet>
          <ProductPage
            product={product}
            isDesktopViewport={isDesktopViewport}
            productImagesBrowserStatus={productImagesBrowserStatus}
            productImageFeatured={productImageFeatured}
            toggleProductImagesBrowser={toggleProductImagesBrowser}
            setCurrentProductImages={setCurrentProductImages}
          />
          {/* {product.images.map(x => {
            return (
              <GatsbyImage
                key={x.file.id}
                image={getImage(x.file)}
                alt={x.alt}
              />
            )
          })} */}

        </>
      )}
    </InterfaceContext.Consumer>
  )
}

export default Product

export const query = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        siteUrl
        title
        description
      }
    }
    product(fields: { slug: { eq: $slug } }) {
      id
      title
      collection
      description
      images {
        alt
        file {
          id
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED, 
              height: 910, 
              width: 910, 
              formats: [AUTO, WEBP, AVIF])
          }
        }
      }
      variants {
        id
        name
        amount
        currency
        availableForSale
      }
    }
  }
`
