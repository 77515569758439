import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import * as remark from 'remark'
import * as remarkHtml from 'remark-html'

import { breakpoints, colors, fonts, spacing } from '../../utils/styles'
import { numberFormatter } from '../../utils/helpers'

const ProductSpecsRoot = styled('div')`
  padding: 0 ${spacing.md}px;

  @media (min-width: ${breakpoints.tablet}px) {
    padding: ${spacing['2xl']}px ${spacing.xl}px 0;
  }
`

const Name = styled('h1')`
  color: ${colors.brandDark};
  font-family: ${fonts.heading};
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0;
`

const Description = styled('p')`
  color: ${colors.text};
  font-size: 1rem;
  line-height: 1.5;
`

const Price = styled('div')`
  color: ${colors.brand};
  font-size: 1.8rem;
  font-weight: 500;
  letter-spacing: -0.02em;

  span {
    color: ${colors.textLight};
  }
`

const ProductSpecs = props => {
  const {
    product: {
      title,
      variants: [variant]
    }
  } = props

  const { amount, currency } = variant

  const description = remark().use(remarkHtml).processSync(props.product.description)

  return (
    <ProductSpecsRoot>
      <Name>{title}</Name>
      <Description dangerouslySetInnerHTML={{ __html: description }} />
      <Price>
        <span>{currency}</span> ₱{numberFormatter.format(amount)}
      </Price>
    </ProductSpecsRoot>
  )
}

ProductSpecs.propTypes = {
  product: PropTypes.object.isRequired
}

export default ProductSpecs
